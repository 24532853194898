import React, {useEffect, useRef, useState} from "react";
import intro_full from "../../video/intro2.mp4";
import intro_mobile from "../../video/intro_mobile.mp4";
import "./Intro.sass";
import {AnimateKeyframes} from "react-simple-animate";
import {visIntro, changeVisIntro} from "../../reducers/dataReducer";
import {useDispatch, useSelector} from "react-redux";

export function Intro() {
    let vis = useSelector(visIntro);
    let intro;
    const availableScreenWidth = window.screen.availWidth;
    if (availableScreenWidth < 1024 ){
        intro = intro_mobile;
    }else {
        intro = intro_full;
    }
    const dispatch = useDispatch();


    const [loaded, setLoaded] = useState(0);
    const [showProgress, setShowProgress] = useState(true);
    console.log(loaded, showProgress);
    const videoRef = useRef(null);
    const handleProgress = () => {
    const video = videoRef.current;
        if (video && video.buffered.length > 0) {
          console.log("чота происходит")
          const bufferedEnd = video.buffered.end(video.buffered.length - 1);
          const duration = video.duration;
          if (duration > 0) {
            setLoaded(bufferedEnd / duration);
          }
        }
    };
    const handleLoadedData = () => {
        setLoaded(1); // Устанавливаем прогресс загрузки в 100%
        setTimeout(() => dispatch(changeVisIntro()), 6500);
    };
    useEffect(() => {
        if (loaded === 1) {
          const timer = setTimeout(() => {
            setShowProgress(false); // Скрываем прогресс-бар через 3 секунды
          }, 1000);
          return () => clearTimeout(timer); // Очищаем таймер при размонтировании
        }
    }, [loaded]);
    return (
        <div className={vis ? "intro" : "hided"}>
                <AnimateKeyframes
                        play
                        delay={5.7}
                        duration={1}
                        keyframes={["opacity: 1", "opacity: 0"]}
                        iterationCount={1}
                    >
                <div className={"focus-back"}></div>
                {vis &&
                        <video className="video"
                               autoPlay="autoplay"
                               muted="muted"
                               preload="auto"
                               onProgress={handleProgress}
                               onLoadedData={handleLoadedData}
                               ref={videoRef}
                        >
                            <source src={intro} type="video/mp4"/>
                        </video>
                }
                {showProgress && (
                    <div style={{ width: "100%", height: "10px", backgroundColor: "#ddd" }} className={"progress-bar"}>
                      <div
                        style={{
                          width: `${loaded * 100}%`,
                          height: "100%",
                          backgroundColor: "blue",
                        }}
                      ></div>
                    </div>
                )}
                </AnimateKeyframes>
        </div>
    );
}