import {servicesList} from "../../reducers/dataReducer";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {Accordion, AccordionItem} from "@szhsin/react-accordion";
import "./ServisesMenuMobile.sass";
import {CiCircleMinus, CiCirclePlus} from "react-icons/ci";


export function ServisesMenuMobile(props) {
    let services = useSelector(servicesList);

    let status = props.menustatus
    console.log(status);


    return(
        <div className={"sub-menu-services-mobile"}>
            <Accordion transition transitionTimeout={250} allowMultiple>
                {services.map((service) => {
                        return (
                            <AccordionItem className={"razdel"} key={service.id}
                               header={({ state }) => {
                                   if (state.isEnter){
                                       return(<>{service.name} <CiCircleMinus/></>)
                                   }else {
                                       return (<>{service.name} <CiCirclePlus/></>)
                                   }
                               }}>
                                <div className={"spec-articles-mobile"}>
                                    <ul>
                                        {service.spec_articles.map((spec) => {
                                            return (
                                                <li key={spec.id}><Link
                                                    to={"/spec-articles/" + spec.name.replaceAll(" ", '_')} onClick={() => status(false)}>{spec.name}</Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className={"diseases-mobile"}>
                                    <ul>
                                        {service.diseases.map((item) => {
                                            return (
                                                <li key={item.id}><Link
                                                    to={"/diseases/" + item.name.replaceAll(" ", '_').replaceAll("?", "q")} onClick={() => status(false)}>{item.name}</Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </AccordionItem>
                        )
                })}
            </Accordion>
        </div>
    );
}